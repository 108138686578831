import React from 'react';

import dynamic from 'next/dynamic';

import RichgoLdJSON from '../../mapTestSrc/mode/maps/richgoMap/RichgoLdJSON';

const NaverMapSystemUI_V2 = dynamic(
  () => import('../../mapTestSrc/mode/maps/baseComp/NaverMapSystemUI_V2'),
  {
    ssr: false,
  }
);
const Page = () => {
  return (
    <>
      <RichgoLdJSON />
      <NaverMapSystemUI_V2 />
    </>
  );
};
export default Page;
