import React from 'react';

import Head from 'next/head';

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://m.richgo.ai',
  name: '리치고',
  alternateName: '부동산 분석 No.1 리치고',
  description:
    '리치고에서는 아파트, 토지, 건물의 시세/실거래가 정보를 제공하며 경매! 호재와 학군, 미래 시세까지 다양한 부동산 분석 자료를 제공합니다.',
  publisher: {
    '@type': 'Organization',
    name: '리치고',
    logo: {
      '@type': 'ImageObject',
      url: 'https://m.richgo.ai/logo_for_search.png',
    },
  },
  sameAs: ['https://expert.richgo.ai/', 'https://www.youtube.com/channel/UC3nsb3SxlRrJQ9egkKNnlfg'],
};
const jsonLd2 = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: '리치고',
  url: 'https://m.richgo.ai',
  logo: 'https://m.richgo.ai/logo_for_search.png',
  // contactPoint: {
  //   '@type': 'ContactPoint',
  //   // telephone: '',
  //   contactType: 'Customer Service',
  //   areaServed: 'KR',
  //   availableLanguage: 'Korean',
  // },
  sameAs: ['https://expert.richgo.ai/', 'https://www.youtube.com/channel/UC3nsb3SxlRrJQ9egkKNnlfg'],
};
const RichgoLdJSON = () => {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd2) }}
      />
    </Head>
  );
};
export default RichgoLdJSON;
